var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item-body"
  }, [_c('div', {
    staticClass: "x3 header"
  }, [_c('div', [_vm._v(" 活动： "), _c('el-select', {
    staticClass: "activity-select",
    attrs: {
      "placeholder": "请选择"
    },
    on: {
      "change": _vm.getPublicFileList
    },
    model: {
      value: _vm.checkActivity,
      callback: function callback($$v) {
        _vm.checkActivity = $$v;
      },
      expression: "checkActivity"
    }
  }, _vm._l(_vm.activityList, function (item, i) {
    return _c('el-option', {
      key: i,
      attrs: {
        "label": item.activityName,
        "value": item.id
      }
    });
  }), 1)], 1)]), _c('div', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "header-mol"
  }, [_vm._m(0), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.createDialog('');
      }
    }
  }, [_vm._v("创建公示")])], 1), _c('div', [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "border": "",
      "data": _vm.tableData
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "title",
      "label": "文档标题"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "x aic"
        }, [scope.row.filePath.includes('.pdf') ? _c('img', {
          attrs: {
            "src": require("@/assets/icon_pdf.png"),
            "alt": "",
            "srcset": "",
            "width": "20px",
            "height": "20px"
          }
        }) : ['.jpg', '.png'].some(function (op) {
          return scope.row.filePath.includes(op);
        }) ? _c('img', {
          attrs: {
            "src": require("@/assets/icon_xuzhi.png"),
            "alt": "",
            "srcset": "",
            "width": "20px"
          }
        }) : _vm._e(), _c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.createDialog(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.title))])], 1)];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "updateDate",
      "label": "更新时间"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "updateUser",
      "label": "更新人"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "address",
      "label": "操作",
      "width": "150px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.createDialog(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.deletePurchaseaItem(scope.row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1)], 1)]), _c('el-dialog', {
    staticClass: "dialog-con",
    attrs: {
      "title": _vm.editParams.fileId ? '编辑认购公示' : '创建认购公示',
      "visible": _vm.dialogVisible,
      "width": "500px",
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "model": _vm.editParams,
      "label-width": "100px",
      "size": "small"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "文档名称:",
      "rules": [_vm.rules.required()],
      "prop": "title"
    }
  }, [_c('el-input', {
    attrs: {
      "maxlength": "25"
    },
    model: {
      value: _vm.editParams.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editParams, "title", $$v);
      },
      expression: "editParams.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "附件:",
      "rules": [_vm.rules.required()]
    }
  }, [_vm.fileList.length ? _c('div', {
    staticClass: "x"
  }, [_c('img', {
    attrs: {
      "src": "",
      "alt": ""
    }
  }), _c('div', [_vm._v(_vm._s(_vm.fileList[0].name))])]) : _c('div', {
    staticClass: "text"
  }, [_vm._v("支持PDF、JPG格式，大小不超过2M")]), _c('div', [_c('el-upload', {
    staticClass: "picture-uploader",
    attrs: {
      "action": "",
      "list-type": "picture-card",
      "multiple": "",
      "auto-upload": false,
      "on-change": _vm.imgChangeNUploads
    }
  }, [_c('el-button', {
    attrs: {
      "type": "text"
    }
  }, [_vm._v(_vm._s(_vm.fileList.length ? "重新上传附件" : "上传附件"))])], 1)], 1)])], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("确 定")])], 1)], 1), _c('el-dialog', {
    attrs: {
      "visible": _vm.centerDialogVisible,
      "width": "420px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.centerDialogVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "x delete-content"
  }, [_c('i', {
    staticClass: "el-icon-question"
  }), _c('div', [_c('span', [_vm._v("删除公示")]), _vm._v(" "), _c('br'), _c('span', [_vm._v("确定删除该认购公示文档吗？")])])]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click($event) {
        _vm.centerDialogVisible = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "small"
    },
    on: {
      "click": _vm.deletePurchasea
    }
  }, [_vm._v("确 定")])], 1)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("网上认购公示文件")]), _c('p', {
    staticClass: "summary"
  }, [_vm._v(" 认购公示是指需要对外公示相关文件；请以PDF/JPG文件形式上传； ")])]);

}]

export { render, staticRenderFns }