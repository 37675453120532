<template>
  <div class="item-body">
    <div class="x3 header">
      <div>
        活动：
        <el-select
          v-model="checkActivity"
          placeholder="请选择"
          class="activity-select"
          @change="getPublicFileList"
        >
          <el-option
            v-for="(item, i) in activityList"
            :key="i"
            :label="item.activityName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="section">
      <div class="header-mol">
        <div class="info">
          <h3 class="title">网上认购公示文件</h3>
          <p class="summary">
            认购公示是指需要对外公示相关文件；请以PDF/JPG文件形式上传；
          </p>
        </div>
        <el-button type="primary" @click="createDialog('')">创建公示</el-button>
      </div>
      <div>
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column prop="title" label="文档标题">
            <template slot-scope="scope">
              <div class="x aic">
                <img
                  src="@/assets/icon_pdf.png"
                  alt=""
                  srcset=""
                  v-if="scope.row.filePath.includes('.pdf')"
                  width="20px"
                  height="20px"
                />
                <img
                  src="@/assets/icon_xuzhi.png"
                  alt=""
                  srcset=""
                  v-else-if="
                    ['.jpg', '.png'].some(op => scope.row.filePath.includes(op))
                  "
                  width="20px"
                />
                <el-button type="text" @click="createDialog(scope.row)">
                  {{ scope.row.title }}</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="updateDate" label="更新时间">
          </el-table-column>
          <el-table-column prop="updateUser" label="更新人"> </el-table-column>
          <el-table-column prop="address" label="操作" width="150px">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="createDialog(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="deletePurchaseaItem(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :title="editParams.fileId ? '编辑认购公示' : '创建认购公示'"
      :visible.sync="dialogVisible"
      width="500px"
      class="dialog-con"
      :before-close="handleClose"
    >
      <el-form
        class="form"
        ref="form"
        :model="editParams"
        label-width="100px"
        size="small"
      >
        <el-form-item
          label="文档名称:"
          :rules="[rules.required()]"
          prop="title"
        >
          <el-input v-model="editParams.title" maxlength="25"></el-input>
        </el-form-item>
        <el-form-item label="附件:" :rules="[rules.required()]">
          <div class="x" v-if="fileList.length">
            <img src="" alt="" />
            <div>{{ fileList[0].name }}</div>
          </div>
          <div class="text" v-else>支持PDF、JPG格式，大小不超过2M</div>
          <div>
            <el-upload
              action=""
              class="picture-uploader"
              list-type="picture-card"
              multiple
              :auto-upload="false"
              :on-change="imgChangeNUploads"
              ><el-button type="text">{{
                fileList.length ? "重新上传附件" : "上传附件"
              }}</el-button></el-upload
            >
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="save" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="centerDialogVisible" width="420px">
      <div class="x delete-content">
        <i class="el-icon-question"></i>
        <div>
          <span>删除公示</span> <br />
          <span>确定删除该认购公示文档吗？</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" size="small"
          >取 消</el-button
        >
        <el-button type="primary" @click="deletePurchasea" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixin from "@/components/upload/mixin";
import {
  getPublicFileList,
  editPurchasea,
  deletePurchasea
} from "@/api/purchase";
import { getActivityList } from "@/api/marketActivity";
import { mapState } from "vuex";
import rules from "@/utils/rules";
export default {
  mixins: [mixin],
  data() {
    return {
      rules,
      activityList: [],
      checkActivity: "",
      dialogVisible: false,
      centerDialogVisible: false,
      tableData: [],
      editParams: {
        estateId: "",
        // "fileId": 0,
        filePath: "",
        purchaseType: 1,
        relationId: "",
        title: ""
      }
    };
  },
  computed: {
    ...mapState({
      estateInfo: state => state
    }),
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  created() {
    this.getActivityList();
  },
  watch: {
    fileList(val) {
      if (val.length) {
        if (!this.editParams.title) {
          this.editParams.title = val[0].name.split(".")[0];
        }
      }
    }
  },
  methods: {
    async save() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;

        Object.assign(this.editParams, {
          estateId: this.estateId,
          filePath: this.fileList[0].url,
          relationId: this.checkActivity
        });

        let { fileId } = this.editParams;
        let data = await editPurchasea(this.editParams);

        if (data) {
          this.$showSuccess(fileId ? "修改成功" : "创建成功");
          this.dialogVisible = false;
          this.getPublicFileList();
        }
      });
    },
    deletePurchaseaItem(item) {
      this.centerDialogVisible = true;
      this.deleteItem = item;
    },
    async deletePurchasea() {
      if (!this.deleteItem) return;

      let { fileId } = this.deleteItem;
      let data = await deletePurchasea({ id: fileId });
      if (data) {
        this.$showSuccess("删除成功");
        this.centerDialogVisible = false;
        this.getPublicFileList();
      }
    },
    createDialog(item) {
      if (item) {
        let { filePath, title } = item;
        this.editParams = item;
        let arr = [".pdf", ".jpg"];
        let fil = arr.filter(op => filePath.includes(op));
        this.fileList = [{ name: title + fil[0], url: filePath }];
      } else {
        this.editParams = {
          estateId: "",
          // "fileId": 0,
          filePath: "",
          purchaseType: 1,
          relationId: "",
          title: ""
        };
        this.fileList = [];
        this.$nextTick(() => {
          this.$refs.form.resetFields();
        });
      }
      this.dialogVisible = true;
    },
    async getActivityList() {
      this.activityList = (await getActivityList(this.estateId)) || [];

      if (this.activityList.length) {
        this.checkActivity = this.activityList[0].id;
        this.getPublicFileList();
      }
    },
    async getPublicFileList() {
      this.tableData = await getPublicFileList({
        estateId: this.estateId,
        purchaseType: 1,
        relationId: this.checkActivity
      });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-con {
  .text {
    font-size: 13px;
    color: #999;
  }
}
.section {
  .header-mol {
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
.delete-content {
  i {
    font-size: 30px;
    color: #f2bc18;
  }
  div {
    margin-left: 20px;
  }
  span:last-child {
    color: #999;
  }
}
.item-body {
  background-color: #ffffff;
  padding: 20px;

  ::v-deep .picture-uploader {
    .el-upload-list--picture-card {
      display: none;
    }
    .el-upload--picture-card {
      width: 106px;
      height: auto;
      line-height: normal;
    }
  }
  .header {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .activity-select {
      width: 300px;
      ::v-deep {
        .el-input {
          .el-input__inner {
            border: none;
            font-size: 16px;
            color: #3470fc;
          }
        }
      }
    }
  }
}
</style>
