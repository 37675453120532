import request from "@/utils/request";

/**
 * 获取列表
 */
export function getPublicFileList(data) {
  return request({
    url: `/api/v1/fxb/purchase/getPublicFileList`,
    method: "post",
    data
  });
}

/**
 * 编辑
 */
export function editPurchasea(data) {
  return request({
    url: `/api/v1/fxb/purchase/edit`,
    method: "post",
    data
  });
}

/**
 * 删除
 */
export function deletePurchasea(data) {
  return request({
    url: `/api/v1/fxb/purchase/delete`,
    method: "post",
    data
  });
}
